import React from 'react';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../styles/global.scss';
import '../styles/privacy.scss';

const PrivacyPolicy = () => (
    <Layout>
        <SEO title="Privacy Policy" />
        <div className="container pageHero">
            <motion.h1
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                }}
                className="pageHeadline"
            >
                Privacy Policy.
            </motion.h1>
            <span className="privacy-text">
                Thank you for utilizing Earth Hero. We hope the content and tools provided by the
                Earth Hero app, website, and social media sites (referred to here as the Earth Hero
                Platform) help make it easy for you to take climate actions. Earth Hero is a
                non-profit, all-volunteer group that formed in 2019. We seek to provide the best
                information regarding actions to address the climate emergency, carbon emissions,
                and to increase your social impact.
            </span>
            <span className="privacy-text">
                At Earth Hero, we take the privacy of our users (“Users,” “You,” or “Your”) very
                seriously. This Privacy Policy explains our practices regarding the collection, use,
                and disclosure of information that Earth Hero (“Earth Hero,” “Us,” or “We”) or its
                third party service providers collect (“Earth Hero“ or “Application,” or “App”).
                &nbsp;Please read this Privacy Policy carefully before using the Application. By
                accessing or using Earth Hero, you consent to the terms and conditions of this
                Privacy Policy.
            </span>
            <span className="privacy-text">
                This Privacy Policy applies only to information that you provide to us and is
                intended to help you understand:
            </span>
            <span className="privacy-text">What information we collect about you</span>
            <span className="privacy-text">Purposes for which we may process your information</span>
            <span className="privacy-text">
                What is the lawful basis for processing personal information
            </span>
            <span className="privacy-text">What is the data retention period</span>
            <span className="privacy-text">What are your rights as the data subject</span>
            <span className="privacy-text">Whether we share information provided to us</span>
            <span className="privacy-text">
                How we process information we collect internationally
            </span>
            <span className="privacy-text doubleSpace">
                How you can access or control your personal information
            </span>

            <span className="privacy-text">
                <strong>Personal Information</strong>
            </span>
            <span className="privacy-text">
                Your use of Earth Hero may cause certain information, including information you may
                consider personal to you (“Personal Information”), to be stored locally on your
                mobile phone, tablet, media player, or personal computer (“Device”). This data may
                include, but is not limited to, your first and last name, email address, and social
                media information. It may also include specific details about your carbon pollution
                (“Carbon Pollution Data”). We will treat your Personal Information and Carbon
                Pollution Data as confidential. We will not share your Personal Information or
                Carbon Pollution Data with third parties, such as third party service providers
                and/or advertisers.
            </span>

            <span className="privacy-text">
                We may automatically collect the following types of information as you use Earth
                Hero:
            </span>
            <span className="privacy-text">
                <strong>Usage Information</strong>: We collect certain information automatically
                through your use of our App, such as which features of our App you access, the
                frequency of access, updates made on our App, and what you click on while on our
                App.
            </span>
            <span className="privacy-text">
                <strong>Location Information</strong>: We may collect information about your actual
                location that may be determined from GPS and other sensors that may reveal
                information on nearby devices, Wi-Fi access points, and cell towers.
            </span>
            <span className="privacy-text">
                <strong>Device Information</strong>: We may collect information about the device you
                are using, such as hardware model, operating system, application version number,
                browser, and IP addresses.
            </span>
            <span className="privacy-text doubleSpace">
                <strong>Mobile Device Information</strong>: In addition to the Device Information
                listed above, when you access our App, we may also collect the unique device
                identifier assigned to that device, mobile carrier, operating system, and other
                device attributes. We may also access functions on your mobile device for the
                operation of our mobile application, which can include: telephone, camera, browser,
                social media apps, or GPS.
            </span>

            <span className="privacy-text">
                <strong>Cookies and Other Tracking Technologies</strong>
            </span>
            <span className="privacy-text">
                “Cookies” are small text files that are stored within your computer’s or mobile
                device’s memory by a website through your browser. Cookies enable a website or
                mobile app such as Earth Hero’s to keep track of your activities on that website or
                app, and to improve your use of the website or app, such as by keeping track of your
                preferences. You can change your browser settings on your personal computer or
                mobile device so that cookies are not stored. If you reject cookies, you may still
                use our Site, but you may not have access to some of our Site’s functions and your
                ability to use some areas of our Site may be limited.
            </span>
            <span className="privacy-text doubleSpace">
                We may also use other website or mobile tracking technologies to identify you and
                keep track of your preferences, prevent fraudulent activity or improve Site
                security, assess the performance of our Site, and deliver content to you based on
                your interests on our Site and third-party websites.
            </span>

            <span className="privacy-text">
                <strong>Data usage</strong>
            </span>
            <span className="privacy-text">
                We may process personal information provided through the means outlined above for
                the following purposes:
            </span>
            <span className="privacy-text">To provide you access to our application</span>
            <span className="privacy-text">
                For safety and security. This includes to prevent, detect, and investigate, fraud,
                security breaches, potentially prohibited or illegal activities, and enforce our
                Terms &amp; Conditions
            </span>
            <span className="privacy-text">
                For research and development. We are always looking for ways to make our application
                smarter, faster, secure, and useful to you. This includes information about features
                you interact with on our application and actions you take on these features
            </span>
            <span className="privacy-text">
                For personalized user experience. We integrate usage data with anonymized user
                profiles to understand your preferences and behavior on our application better. This
                helps us tailor our services and content to better suit your needs, ensuring a more
                personalized and relevant experience for you.
            </span>
            <span className="privacy-text">
                To communicate with you about our application. We use your contact information to
                resolve problems with the site, applications or tools, or for other purposes
                authorized by law.
            </span>
            <span className="privacy-text">
                To add you to our email list to send limited messages about the Earth Hero app and
                addressing climate change, which you can unsubscribe from at any time through a link
                provided in every email.
            </span>
            <span className="privacy-text">
                With your consent: We use information about you where you have given us consent to
                do so for a specific purpose not listed above.
            </span>
            <span className="privacy-text doubleSpace">
                Disclosure to Government: We reserve the right to monitor and disclose, without your
                consent, any communications or Private Information, whenever requested to do so by
                courts, government agencies, or by law enforcement authorities.
            </span>

            <span className="privacy-text">
                <strong>What is the lawful basis for processing personal information?</strong>
            </span>
            <span className="privacy-text">
                In Processing your personal information in connection with the purposes set out in
                this Policy, we rely on one or more of the following legal bases, depending on the
                circumstances:
            </span>
            <span className="privacy-text">
                Consent: We may Process your personal information where we have obtained your prior,
                express consent.
            </span>
            <span className="privacy-text">
                Compliance with applicable law: We may Process your personal information where the
                Processing is required by applicable law;
            </span>
            <span className="privacy-text">
                Vital interests: We may Process your personal information where the Processing is
                necessary to protect the vital interests of any individual; or
            </span>
            <span className="privacy-text">
                Legitimate interests: We may Process your personal information where we have a
                legitimate interest in carrying out the Processing for the purpose of managing,
                operating or promoting our organization, and that legitimate interest is not
                overridden by your interests, fundamental rights, or freedoms.
            </span>
            <span className="privacy-text">
                GDPR and legal bases for processing (for EEA users): If you are an individual in the
                European Economic Area (EEA), we collect and process information about you only
                where we have legal bases for doing so under applicable EU laws. The legal bases
                depend on the Applications you use and how you use them. This means we collect and
                use your information only where:
            </span>
            <span className="privacy-text">
                We need it to provide you with the Application, including to operate the
                Application, provide customer support and personalized features and to protect the
                safety and security of the Application;
            </span>
            <span className="privacy-text">
                It satisfies a legitimate interest (which is not overridden by your data protection
                interests), such as to deliver access to features of interest within the application
            </span>
            <span className="privacy-text">
                You give us consent to do so for a specific purpose; or
            </span>
            <span className="privacy-text">
                We need to process your data to comply with a legal obligation.
            </span>
            <span className="privacy-text">
                <strong>Data retention period</strong>
            </span>
            <span className="privacy-text">
                We will only retain your data for as long as you maintain a valid Earth Hero user
                account.
            </span>
            <span className="privacy-text">
                Users have the ability to delete their account from within the app at any time. This
                will automatically delete any retained data.
            </span>
            <span className="privacy-text doubleSpace">
                We reserve the right to use anonymized data for analytical purposes after an account
                is deleted.
            </span>
            <span className="privacy-text">
                <strong>Rights of the data subject</strong>
            </span>
            <span className="privacy-text">
                As the data subject, you can exercise certain rights with reference to the data
                Processed by the controller.
            </span>
            <span className="privacy-text">
                If you have consented to our use of information about you for a specific purpose,
                you have the right to change your mind at any time, but this will not affect any
                processing that has already taken place.
            </span>
            <span className="privacy-text">In particular, you have the right to:</span>
            <ul className="privacy-text">
                <li>Withdraw your consent to the Processing of your data.</li>
                <li>Request access to your data from the controller. </li>
                <li>Verify and request a rectification or an update to your data. </li>
                <li>Obtain a restriction of the Processing of your data.</li>
                <li>Object to the Processing of your data.</li>
                <li>Request an erasure of your data. </li>
                <li>Lodge a complaint with a supervisory authority.</li>
            </ul>
            <span className="privacy-text">
                <strong>Sharing Information</strong>
            </span>
            <span className="privacy-text">
                Access to your personal data is limited to a small number of individuals on the app
                development team and customer service team.
            </span>
            <span className="privacy-text doubleSpace">
                Earth Hero will never automatically share your information to social networks and/or
                third parties without your consent. We are in no way responsible for the protection
                of any information that you choose to share with social networks. You and such
                social networks are solely responsible for the protection of such information.
                Please review any applicable policies and procedures of such social networks before
                sharing your information therein.
            </span>

            <span className="privacy-text">
                <strong>Children’s Privacy</strong>
            </span>
            <span className="privacy-text doubleSpace">
                We do not knowingly collect or solicit personal information from children under 16.
            </span>

            <span className="privacy-text">
                <strong>International Users</strong>
            </span>
            <span className="privacy-text doubleSpace">
                Earth Hero is located in the United States and is subject to the applicable state
                and federal laws of the United States. If you use Earth Hero, you consent to the use
                and disclosure of information in accordance with this Privacy Policy and subject to
                such laws.
            </span>

            <span className="privacy-text">
                <strong>Contact Us</strong>
            </span>
            <span className="privacy-text doubleSpace">
                To exercise your rights as the data subject or if you have any questions about the
                policies described in this statement, you can email Earth Hero Data Controller
                directly at <a href="mailto:privacy@earthhero.org">privacy@earthhero.org</a>.
            </span>
            <span className="privacy-text">
                <strong>Updating Our Privacy Policy </strong>
            </span>

            <span className="privacy-text doubleSpace">
                We reserve the right to modify or update this Privacy Policy at any time and will
                make reasonable efforts to publish such changes here and on EarthHero.org. Your
                continued use of Earth Hero constitutes your acceptance of this Privacy Policy.
                Please check this Privacy Policy for any such updates.
            </span>
        </div>
    </Layout>
);

export default PrivacyPolicy;
